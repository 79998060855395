// Records.js

import React, { useEffect, useState } from 'react';
import { Center, Table, Thead, Tbody, Tr, Th, Td, Button, Text, Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
const dummyRecords = [
    { userName: 'Player1', lastMatch: '2023-08-12 15:30', totalWin: 5 },
    { userName: 'Player2', lastMatch: '2023-08-11 18:45', totalWin: 3 },
    { userName: 'Player3', lastMatch: '2023-08-10 12:15', totalWin: 2 },
];

const formatDate = (dateTimeString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
    return new Date(dateTimeString).toLocaleDateString('en-US', options);
};

const Records = () => {

    const [records, setRecords] = useState([]);

    useEffect(() => {
        async function fetchRecords() {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND}/record/getall`);
                if (response.ok) {
                    const data = await response.json();
                    setRecords(data);
                } else {
                    console.error('Failed to fetch records');
                }
            } catch (error) {
                console.error('Error fetching records:', error);
            }
        }

        fetchRecords();
    }, []);

    return (
        <Center h="100vh">
            <Box>
                <Button as={Link} to="/" mt={2} width="100%" colorScheme="teal">
                    Play Game
                </Button>
                <Table variant="simple" >
                    <Thead>
                        <Tr>
                            <Th>Username</Th>
                            <Th>Last Match</Th>
                            <Th>Total Wins</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {records.map((record, index) => (
                            <Tr
                                key={index}
                                _hover={{ backgroundColor: 'gray.100', cursor: 'pointer' }}
                                transition="background-color 0.3s ease"
                            >
                                <Td>{record.userName}</Td>
                                <Td>{formatDate(record.lastMatch)}</Td>
                                <Td>{record.totalWins}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>

        </Center>
    );
};

export default Records;
