import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Input, FormControl, FormLabel } from '@chakra-ui/react';

const SubmissionModal = ({ isOpen, onClose, onNameSubmit }) => {
    const [playerName, setPlayerName] = useState('');
    const [hasEnteredUsername, setHasEnteredUsername] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleNameChange = (event) => {
        setPlayerName(event.target.value);
        setHasEnteredUsername(false); // Reset validation when user types
    };

    const handleSubmit = async () => {
        if (playerName.trim() !== '') {
            setIsSubmitting(true);

            const backendUrl = process.env.REACT_APP_BACKEND;

            try {
                const response = await fetch(`${backendUrl}/record/add`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userName: playerName }),
                });

                if (response.ok) {
                    onNameSubmit(playerName);
                    onClose();
                } else {
                    // Handle error case
                }
            } catch (error) {
                // Handle error case
            } finally {
                setIsSubmitting(false);
            }
        } else {
            setHasEnteredUsername(true);
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={hasEnteredUsername ? undefined : onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Enter Your Details</ModalHeader>
                <ModalBody>
                    <Input
                        placeholder="Player Name"
                        value={playerName}
                        onChange={handleNameChange}
                        borderColor={hasEnteredUsername && playerName.trim() === '' ? 'red' : 'gray'}
                    />

                </ModalBody>
                <ModalFooter>
                    <Button variant="outline" mr={2} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button colorScheme="blue" onClick={handleSubmit} isLoading={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default SubmissionModal;
