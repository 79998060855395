import React from 'react';
import { Box, Text, UnorderedList, ListItem } from '@chakra-ui/react';

const GameRules = () => {
    return (
        <Box mb={4}>
            <Text>
                Welcome to the 3-Player Tic-Tac-Toe Game!
                Win by completing a row, column, or diagonal with your symbol.
            </Text>
            <UnorderedList>
                <ListItem>A player wins if any single row or column is occupied by their symbol.</ListItem>
                <ListItem>A player wins if any diagonal is occupied by their symbol.</ListItem>
                <ListItem>The first player to complete a winning combination wins.</ListItem>
                <ListItem>The game ends in a draw if all cells are filled.</ListItem>
                <ListItem>Plan your moves carefully to outmaneuver opponents.</ListItem>
            </UnorderedList>
        </Box>
    );
};

export default GameRules;
