import React, { useState } from 'react';
import { Box, Center, Grid, GridItem, Button, Text } from '@chakra-ui/react';
import GameRules from './GameRules';
import SubmissionModal from './SubmissionModal';
import { Link } from 'react-router-dom';

const TicTacToeBoard = () => {
  const [board, setBoard] = useState(Array(5).fill(Array(5).fill(null)));
  const [currentPlayer, setCurrentPlayer] = useState('X');
  const [winner, setWinner] = useState(null);
  const cellSize = '70px';
  const players = ['X', 'Y', 'Z'];
  const playerNames = {
    'X': 'Player 1',
    'Y': 'Player 2',
    'Z': 'Player 3'
  }
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleNameSubmit = (playerName) => {
    // Save playerName and winner's information as needed
    console.log(`Player ${playerNames[winner]} wins!`);
  };

  const handleCellClick = (rowIndex, colIndex) => {
    if (!board[rowIndex][colIndex] && !winner) {
      const newBoard = board.map((row, rIndex) =>
        rIndex === rowIndex ? row.map((cell, cIndex) =>
          cIndex === colIndex ? currentPlayer : cell
        ) : row
      );

      setBoard(newBoard);
      checkWinner(newBoard, rowIndex, colIndex);
      setCurrentPlayer(players[(players.indexOf(currentPlayer) + 1) % players.length]);
    }
  };

  const handleResetGame = () => {
    setBoard(Array(5).fill(Array(5).fill(null)));
    setWinner(null);
    setCurrentPlayer('X');
  };

  const checkWinner = (board, rowIndex, colIndex) => {
    const currentPlayerSymbol = board[rowIndex][colIndex];

    for (let i = 0; i < 5; i++) {
      if (board[rowIndex][i] !== currentPlayerSymbol) {
        break;
      }
      if (i === 4) {
        setWinner(currentPlayerSymbol);
        return;
      }
    }

    for (let i = 0; i < 5; i++) {
      if (board[i][colIndex] !== currentPlayerSymbol) {
        break;
      }
      if (i === 4) {
        setWinner(currentPlayerSymbol);
        setIsModalOpen(true);
        return;
      }
    }

    if (rowIndex === colIndex) {
      for (let i = 0; i < 5; i++) {
        if (board[i][i] !== currentPlayerSymbol) {
          break;
        }
        if (i === 4) {
          setWinner(currentPlayerSymbol);
          setIsModalOpen(true);
          return;
        }
      }
    }

    if (rowIndex + colIndex === 4) {
      for (let i = 0; i < 5; i++) {
        if (board[i][4 - i] !== currentPlayerSymbol) {
          break;
        }
        if (i === 4) {
          setWinner(currentPlayerSymbol);
          setIsModalOpen(true);
          return;
        }
      }
    }

    if (!winner && board.every(row => row.every(cell => cell !== null))) {
      setWinner('Draw');
    }
  };

  return (
    <Center h="100vh">

      <Box >
        <GameRules></GameRules>
        {
          winner !== null ? <p><strong>Winner:</strong> {winner !== "Draw" ? playerNames[winner] : "Draw"}</p> : <p><strong>Current Player: </strong>{playerNames[currentPlayer]}</p>
        }

        <Grid templateColumns={`repeat(5, ${cellSize})`} templateRows={`repeat(5, ${cellSize})`} gap={2} justifyContent="center">
          {board.map((row, rowIndex) =>
            row.map((cell, colIndex) => (
              <GridItem
                key={`${rowIndex}-${colIndex}`}
                bg="white"
                border="1px solid gray"
                display="flex"
                justifyContent="center"
                alignItems="center"
                fontSize="xl"
                fontWeight="bold"
                cursor={!cell && !winner ? 'pointer' : 'default'}
                width={cellSize}
                height={cellSize}
                transition="all 0.3s ease-out" // Add a smooth transition effect
                _hover={{
                  borderColor: 'blue.500', // Change border color on hover
                  transform: 'scale(1.05)', // Apply a slight scale effect on hover
                }}
                onClick={() => handleCellClick(rowIndex, colIndex)}
              >
                {cell}
              </GridItem>
            ))
          )}
        </Grid>
        <SubmissionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onNameSubmit={() => {
            handleNameSubmit();
            handleResetGame();
          }}
        />
        <Button mt={4} onClick={handleResetGame} width="100%">
          Reset Game
        </Button>
        <Button as={Link} to="/records" mt={2} width="100%" colorScheme="teal">
          Records
        </Button>
      </Box>
    </Center>
  );
};

export default TicTacToeBoard;
